.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
  position: relative;
}

.App.light-mode {
  background-color: white;
  color: black;
}

.App-header {
  font-size: 4rem;
}

.light-mode-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.light-mode-button:hover {
  background-color: #21a1f1;
}
